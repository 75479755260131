import React from 'react';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

import FadeIn from 'react-fade-in';


export default function BasicExample() {
  return (
    // <Router basename="/olliketonen">
    <Router>
      <div class="maindiv">
        <FadeIn>
          <h1>OLLI KETONEN AUDIO DESIGN</h1>
          <div class="buttonbox">
            <Link class="buttonw" to="/games">GAMES</Link>
            <Link class="buttonb" to="/art">ART</Link>
            <Link class="buttonc" to="/music">MUSIC</Link>
            <Link class="buttonc" to="/info">INFO</Link>
          </div>
        </FadeIn>
      </div>
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route exact path="/games">
          <Work />
        </Route>
        <Route exact path="/music">
          <Bio />
        </Route>
        <Route exact path="/info">
          <Contact />
        </Route>
        <Route exact path="/art">
          <Art />
        </Route>
      </Switch>
    </Router>
  );
}

function Main() {
  return (
    <div class="maindiv">
      {/* <FadeIn>
        <h1>OLLI KETONEN AUDIO DESIGN</h1>
        <div class="buttonbox">
          <Link class="buttonw" to="/work">WORK</Link>
          <Link class="buttonb" to="/bio">BIO</Link>
          <Link class="buttonc" to="/contact">CONTACT</Link>
        </div>
      </FadeIn> */}
    </div>
  );
}


function Work() {
  let { path, url } = useRouteMatch();
  return (
    <div>
      {/* <Link class="link" to="/">BACK</Link> */}

      <div>

        <FadeIn>
{
          <div>
            <h2></h2>
            <div class="video-container">
              <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/qUmqhm42xDU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>
            </div>
          </div>
  }
         {/* <div class="biodiv">
            <p class="bio">
              <p style={{ textAlign: 'center' }}>Clients and projects I've worked with since 2013:</p>
            </p>
          </div>
*/}
          <div class="biodiv">
            <table>
              <tr>
                <th>Game</th>
                <th>Role</th>
                <th>Year</th>
                <th>Company</th>
              </tr>
              <tr>
                <td>Silence of the Siren</td>
                <td>Sound Designer</td>
                <td>TBA</td>
                <td>Oxymoron Games</td>
              </tr>
              <tr>
                <td>Bike Unchained 3</td>
                <td>Sound Designer</td>
                <td>2023</td>
                <td>Red Bull | Kuuasema</td>
              </tr>
              <tr>
                <td>LEMPO</td>
                <td>Composer & Sound Designer</td>
                <td>2023</td>
                <td>One Trick Entertainment</td>
              </tr>
              <tr>
                <td>Love Simulation EVE (Unreal Engine segments)</td>
                <td>Composer & Sound Designer</td>
                <td>2022</td>
                <td>Avatar's Journey | Espoo Theatre</td>
              </tr>
              <tr>
                <td>Offroad Unchained</td>
                <td>Sound Designer</td>
                <td>2022</td>
                <td>Red Bull | Kuuasema</td>
              </tr>
              <tr>
                <td>Boom Slingers</td>
                <td>Composer & Sound Designer</td>
                <td>2022</td>
                <td>Boom Corp</td>
              </tr>
              <tr>
                <td>Puzzle Combat</td>
                <td>Sound Designer</td>
                <td>2021</td>
                <td>Small Giant Games</td>
              </tr>
              <tr>
                <td>Moka Mera Emotions</td>
                <td>Composer & Sound Designer</td>
                <td>2021</td>
                <td>Moilo | Kuuasema</td>
              </tr>
              <tr>
                <td>Moka Mera Lingua</td>
                <td>Sound Designer</td>
                <td>2020</td>
                <td>Moilo | Kuuasema</td>
              </tr>
              <tr>
                <td>Space Pioneer</td>
                <td>Composer & Sound Designer</td>
                <td>2018</td>
                <td>Vivid Games | Kuuasema</td>
              </tr>
              <tr>
                <td>Angry Birds Seasons</td>
                <td>Assisting Sound Designer</td>
                <td>2016</td>
                <td>Rovio | Kuuasema</td>
              </tr>
              <tr>
                <td>Challengers of Khalea</td>
                <td>Sound Designer</td>
                <td>2016</td>
                <td>Dreamloop Games</td>
              </tr>
              <tr>
                <td>Stardust Galaxy Warriors</td>
                <td>Assisting Sound Designer</td>
                <td>2015</td>
                <td>Dreamloop Games</td>
              </tr>
              <tr>
                <td>Discorun</td>
                <td>Composer</td>
                <td>2015</td>
                <td>Tuokio</td>
              </tr>
              <tr>
                <td>Brickvoid</td>
                <td>Composer</td>
                <td>2013</td>
                <td>Devaxel</td>
              </tr>
            </table>

          </div>



        </FadeIn>

        {/* <Link class="link" to="/">BACK</Link> */}

      </div>
    </div>
  );
}

// export default App;
function Bio() {
  return (
    <FadeIn>
      <div>
        <h2></h2>
        <div class="sound-container">
        <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=OLAK5uy_khzNolPtUcKhVrqh4B4XXRFUsZ1VlVDE8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>
        <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PLdIoLSqM-2V4g6JC2qZydq9xosH9VfOkO" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>

          <iframe
            width="100%" height="750" scrolling="no" frameborder="no" allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/323477977%3Fsecret_token%3Ds-9iKDk&color=%23848496&auto_play=false&show_comments=true&show_user=false&show_reposts=false&show_teaser=false">
          </iframe>

        </div>

        
      </div>

    </FadeIn>

  );
}



function Art() {
  return (
    <div>
      <FadeIn>



        <div>

          <h2></h2>


          <div class="video-container">

            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/9lmVe8HPCMA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>
            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/I2xQPJO1IUA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>
            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/RqFrVer9MmA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>

          </div>

        </div>
      </FadeIn>
    </div>
  );
}


function Contact() {
  return (
    <div>
      <FadeIn>
      
        <p class="bio">
          <br></br>
          I am a sound designer, composer and new media artist based in Finland. I create soundscapes and music for video games and all kinds of other interactive applications. </p>
        <br></br>
        
        <p class="bio">Since 2013, I've designed and produced audio content for multiple video games and other non-linear media by companies such as Red Bull, Small Giant Games, Espoo Theatre, Aalto University and Rovio. With a Bachelor's Degree in Music Technology and Master's Degree in Sound in New Media I have a large set of skills from sound effect creation to technical audio design and programming.
        </p>
        
        <br></br>

        <p class="bio">I am also a classically trained cellist with years of experience in electronic music production and audio engineering. With my background in classical and electronic music I can deliver a wide variety of different musical genres from drafting stage to final mix and master.
        </p>
        
        <br></br>
        
        <p class="bio">Contact me to talk about the audio needs for your next project! 

        {/*<a href="mailto:contact@olliketonen.com">Email</a> <a href="http://www.linkedin.com/in/olli-ketonen/">LinkedIn</a>.*/}
        </p>

        
        
        <h2><pre><a href="mailto:contact@olliketonen.com">EMAIL          </a>
        <a href="https://fi.linkedin.com/in/olli-ketonen">          LINKEDIN</a>
        </pre></h2>
        <br></br>
        <br></br><br></br>
        <br></br>
      </FadeIn>
    </div>
  );
}